import Auth from "cp-client-auth!sofe";
import { onPusher } from "fetcher!sofe";
import { handleError } from "../handle-error";
import { forkJoin } from "rxjs";
import { take } from "rxjs/operators";

export async function userTenantUpdates() {
  forkJoin([
    Auth.getLoggedInUserAsObservable().pipe(take(1)),
    Auth.getTenantAsObservable().pipe(take(1)),
  ]).subscribe(([, tenant]) => {
    setupRoleListener();
    subscribeToTenant(tenant);
  }, handleError);
}

// this is fired off when the logged in user's role is changed
const setupRoleListener = () => {
  onPusher("role-updates").subscribe(() => {
    window.location.reload();
  });
};

// This is fired off when a user disconnects from their third party integration (QBO)
const subscribeToTenant = (tenant) => {
  onPusher(
    "third_party_connection_change",
    `private-tenant-${tenant.id}`
  ).subscribe(() => {
    Auth.refreshAuthToken();

    Auth.refetchTenant(true).catch((err) => {
      if (err && err.status === 401) {
        // ignore
      } else {
        handleError(err);
      }
    });
  });
};
