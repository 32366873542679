import React, { Suspense } from "react";
import {
  CpButton,
  CpModal,
  modalService,
  useGlobalBannerStore,
} from "canopy-styleguide!sofe";
import canopyUrls from "canopy-urls!sofe";
import { handleError } from "../handle-error";
import Auth from "cp-client-auth!sofe";
import { EMPTY } from "rxjs";
import { take, switchMap } from "rxjs/operators";
import { onPusher } from "fetcher!sofe";

export async function uiRefresherBootstrap() {
  Auth.getTenantAsObservable()
    .pipe(
      take(1),
      switchMap((tenant) => {
        if (!tenant) return EMPTY;
        return onPusher("force-ui-refresh", `private-tenant-${tenant.id}`);
      })
    )
    .subscribe(forceUiRefresh);
}

async function forceUiRefresh(event) {
  const currentManifest = window.sofe_manifest;
  let freshManifest = {};
  try {
    freshManifest = await getServices();
  } catch {
    return;
  }

  const shouldRefresh = event.services.some((key) => {
    return currentManifest[`${key}!sofe`] !== freshManifest[key];
  });

  if (!shouldRefresh) return;

  try {
    if (event?.type === "silent") {
      silent();
    } else if (event?.type === "warning") {
      silent();
      warning();
    } else if (event?.type === "nuclear") {
      silent();
      nuclear();
    }
  } catch (e) {
    handleError(e);
  }

  function silent() {
    // reload the page on next routing event
    window.addEventListener("single-spa:routing-event", () => {
      location.reload();
    });
  }
  async function warning() {
    const { setShow } = useGlobalBannerStore.getState();
    setShow(true);
  }
  async function nuclear() {
    // show modal, set timeout to reload between 15 seconds and 10 minutes
    modalService.render(NuclearModal);
    setTimeout(() => {
      location.reload();
    }, getRandomRefreshTimeout());
  }
}

async function getServices() {
  return fetch(`${canopyUrls.getCDNUrl()}/sofe-manifest.json`)
    .then((res) => res.json())
    .then((data) => data.sofe.manifest);
}

function getRandomRefreshTimeout() {
  const min = 15 * 1000; // 15 seconds
  const max = 10 * 60 * 1000; // 10 minutes
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function NuclearModal() {
  return (
    <Suspense fallback={null}>
      <CpModal.Header title="Refresh Required" />
      <CpModal.Body>
        We've made some changes behind the scenes. To continue, please refresh
        the page to apply the latest updates.
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={() => {
            location.reload();
          }}
        >
          Refresh now
        </CpButton>
      </CpModal.Footer>
    </Suspense>
  );
}
