import Auth from "cp-client-auth!sofe";
import { of } from "rxjs";
import { first, catchError } from "rxjs/operators";
import { handleError } from "../handle-error";

export default function (sessionIsValid) {
  return Promise.resolve().then(() => {
    if (sessionIsValid) {
      // In the case that the user is logged in we need to return a promise
      // that will eventually yield the user object as our BlockingPromise
      return Auth.getLoggedInUserAsObservable()
        .pipe(
          first(),
          catchError((err) => {
            if (err.status === 401) {
              // User might not be logged in
              return of({ noUserYet: true });
            } else {
              handleError(err);
            }
          })
        )
        .toPromise();
    } else {
      // If the user isn't logged in we need to return an immediately resolved
      // promise as the blockingPromise so that we can continue onward and
      // eventually call SingleSpa.start() so the login page can render
      return Promise.resolve();
    }
  });
}
