import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";

const BannerNotificationsController = lazy(() =>
  SystemJS.import("notifications-ui!sofe").then((notificationsUi) =>
    notificationsUi.getBannerNotificationsController()
  )
);

let root = null;

export default async function bannerNotificationsBootstrap() {
  let container = document.getElementById("banner-notifications-container");
  if (!container) {
    container = document.createElement("div");
    container.id = "banner-notifications-container";
    document.body.appendChild(container);
  }

  if (!root) {
    root = createRoot(container);
  }

  root.render(
    <Suspense fallback={<div />}>
      <BannerNotificationsController />
    </Suspense>
  );
}
